<template>
    <section class="portfolio">
        <router-link to="/" class="close-btn">
            <svg>
                <use  v-bind="{'xlink:href': getSvgUrl('#close')}" :href="getSvgUrl('#close')"></use>
            </svg>
        </router-link>
        <div class="grid-layout">
            <div class="grid-layout--1">
                <div class="gallery">
                    <swiper :options="swiperOption" ref="mySwiperB">
                        <swiper-slide ref="slides" v-for="item in posts" :key="item.id" :data-type="item.type" :data-swiper-autoplay="item.type ==='video'? 14000 : 5000">
                                <div class="bg-white">
                                    <div class="gallery-slide" :content="item.url">
                                        <div v-if="item.type === 'image'" class="gallery-slide__image" :style="{backgroundImage: 'url(' + item.url + ')'}"></div>
                                        <template v-if="item.type === 'video'">
                                            <VideoBg :sources="[item.url]" :autoplay="false" :img="item.videoImg.length? item.videoImg:''"></VideoBg>
                                        </template>
                                    </div>
                                </div>
                        </swiper-slide>
                    </swiper>
                    <div v-show="content.length" class="text-block" v-html="content"></div>
                    <div class="pagination">
                        <span v-for="item in posts"
                              :key="item.id"
                              :class="item.transition"
                              ref="bullets"
                              class="swiper-pagination-bullet">
                            <span class="filler"></span>
                        </span>
                    </div>

                    <div  class="gallery-arrow gallery-arrow--left">
                        <svg>
                            <use  v-bind="{'xlink:href': getSvgUrl('#arrow')}" :href="getSvgUrl('#arrow')"></use>
                        </svg>
                    </div>
                    <div  class="gallery-arrow gallery-arrow--right">
                        <svg>
                            <use  v-bind="{'xlink:href': getSvgUrl('#arrow')}" :href="getSvgUrl('#arrow')"></use>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import VideoBg from "../VideoBg";

    export default {
        components:{
            VideoBg
        },
        props: ['appData', 'posts'],
        data(){
            return {
                content: '',
                swiperOption: {
                    on: {
                        slideChange: () => {
                            let index = this.swiper.activeIndex;
                            this.$refs.bullets.map(bullet => bullet.classList.remove('isActive','isPassed') );
                            this.$refs.bullets[index].classList.add('isActive');
                            this.playVideo();
                            if (index > 0){
                                for (let i=0; i < index; i++ ){
                                    this.$refs.bullets[i].classList.add('isPassed');
                                }
                            }
                            this.$router.push({name:'portfolioInside', params:{slug:this.posts[index].slug}})
                            this.content = this.posts[index].content

                        }
                     }
                },
            }
        },

        methods: {
            findSlide(){
                let index = this.posts.findIndex(post => post.slug === this.$route.params.slug);
                if (index !== -1){
                    // this.swiper.slideTo(index, 800);
                    this.swiper.slideTo(index, 0);
                } else {
                    this.$router.push({name:'portfolioInside', params:{slug:this.posts[0].slug}});

                }
            },

            playVideo(){
                let index = this.swiper.activeIndex;
                if (typeof index != 'undefined' && typeof this.posts[index].type != 'undefined'){
                    if (this.posts[index].type === 'video'){
                       let video =  this.$refs.slides[index].$el.getElementsByTagName("VIDEO")[0];
                        video.pause();
                        video.currentTime = 0;
                        video.load();
                    }
                }
            },

            getSvgUrl(id){
                return this.appData.svgSpriteUrl + id;
            },
        },

        computed: {
            swiper() {
                return this.$refs.mySwiperB.swiper
            },
        },

        mounted() {
            this.swiper.autoplay.stop();
            let index = this.swiper.activeIndex;
            setTimeout(()=>{

                if (!typeof this.$route.params.slug != "undefined"){
                    this.findSlide();
                    this.content = this.posts[index].content
                }

                if(typeof this.$refs.bullets != "undefined" && this.$refs.bullets.length){
                    this.$refs.bullets[0].classList.add('isActive');
                    this.swiper.autoplay.start();

                } else {
                    setTimeout(()=>{
                        this.$refs.bullets[0].classList.add('isActive');
                        this.swiper.autoplay.start();
                        if (!typeof this.$route.params.slug != "undefined"){
                            this.findSlide();
                            this.content = this.posts[index].content
                        }
                    },1000)
                }

            },1000);
        },
        // metaInfo: {
        //     title: this.appData.homeTitle, // set a title
        //
        // }
    }
</script>
