<template>
    <section class="home-page">
        <div class="grid-layout">
            <div id="stories" class="grid-layout--left">
                <template >
                    <swiper  :options="swiperOption" ref="homeSwiper">
                        <swiper-slide v-for="item in homePosts"
                                      :key="item.id" :data-type="item.type"
                                      ref="slides"
                                      :data-swiper-autoplay="item.type ==='video'? 14000 : 5000">

                                <div v-if="item.type === 'image'" class="image-slide" :style="{backgroundImage: 'url(' + item.url + ')'}">
                                    <div class="artwork-author">{{item.artwork}}</div>
                                </div>
                                <template v-if="item.type === 'video'">
                                    <VideoBg :sources="[item.url]" :autoplay="false" :img="item.videoImg.length? item.videoImg:''">
                                        <div class="artwork-author">{{item.artwork}}</div>
                                    </VideoBg>
                                </template>

                        </swiper-slide>
                    </swiper>
                    <div class="gallery-arrow gallery-arrow--left"></div>
                    <div class="gallery-arrow gallery-arrow--right"></div>
                    <div class="pagination">
                        <span v-for="item in homePosts"
                              :key="item.id"
                              :class="item.transition"
                              ref="bullets"
                              class="swiper-pagination-bullet">
                            <span class="filler"></span>
                        </span>
                    </div>

                </template>
            </div>

            <div class="grid-layout--right">
                <div class="grid-layout--h25 portfolio-block">
                    <router-link to="/portfolio" class="btn-arrow">Social media
                        <svg>
                            <use  v-bind="{'xlink:href': getSvgUrl('#arrow')}" :href="getSvgUrl('#arrow')"></use>
                        </svg>
                    </router-link>
                </div>
                <div class="grid-layout--h25 influencer-block">
                    <a href="https://chocoagency.com" class="btn-arrow" target="_blank">Influencer marketing
                        <svg>
                            <use  v-bind="{'xlink:href': getSvgUrl('#arrow')}" :href="getSvgUrl('#arrow')"></use>
                        </svg>
                    </a>
                </div>
                <div class="grid-layout--h50 about-block">
                    <router-link to="/about" class="btn-arrow">About
                        <svg>
                            <use v-bind="{'xlink:href': getSvgUrl('#arrow')}" :href="getSvgUrl('#arrow')"></use>
                        </svg>
                    </router-link>
                    <a href="uab-turinio-rinkodara-igyvendina-projekta-uab-turinio-rinkodara-skaitmeninimas" class="eu-btn">
                      <span>ES Parama</span>
                      <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import VideoBg from "../VideoBg";
    export default {
        components:{
            VideoBg
        },
        props: ['appData', 'homePosts'],
        name: 'home',
        data(){
            return {
                swiperOption: {
                    on: {
                        slideChange: () => {
                            let index = this.swiper.activeIndex;
                            this.$refs.bullets.map(bullet => bullet.classList.remove('isActive','isPassed') );
                            this.$refs.bullets[index].classList.add('isActive');
                            this.playVideo();
                            if (index > 0){
                                for (let i=0; i < index; i++ ){
                                    this.$refs.bullets[i].classList.add('isPassed');
                                }
                            }

                        }
                    }
                },

            }
        },

        methods: {

            playVideo(){
                let index = this.swiper.activeIndex;
                if (typeof index != 'undefined' && typeof this.homePosts[index].type != 'undefined'){
                    if (this.homePosts[index].type === 'video'){
                        let video =  this.$refs.slides[index].$el.getElementsByTagName("VIDEO")[0];
                        video.pause();
                        video.currentTime = 0;
                        video.load();
                    }
                }
            },

            getSvgUrl(id){
                return this.appData.svgSpriteUrl + id;
            },

        },
        computed: {
            swiper() {
                return this.$refs.homeSwiper.swiper
            },
        },
        mounted(){
            this.swiper.autoplay.stop();
            setTimeout(()=>{
                if(typeof this.$refs.bullets != "undefined" && this.$refs.bullets.length){
                    this.$refs.bullets[0].classList.add('isActive');
                    this.swiper.autoplay.start();
                } else {
                    setTimeout(()=>{
                        this.$refs.bullets[0].classList.add('isActive');
                        this.swiper.autoplay.start();
                    },1000)
                }

            },1000);
        },
        // metaInfo: {
        //     title: this.appData.portfolioTitle, // set a title
        //
        // }

    }
</script>
