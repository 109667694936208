import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import { routes } from './routes/routes';
import VideoBg from 'vue-videobg';
import MetaInfo from 'vue-meta-info';

Vue.use(MetaInfo);
Vue.component('video-bg', VideoBg);
Vue.use(VueRouter);

const globalOptions = {
    navigation: {
        nextEl: '.gallery-arrow--right',
        prevEl: '.gallery-arrow--left',
    },
    speed: 0,
    autoplay: {
        delay:'' ,
        disableOnInteraction: false,
    },

    // effect: 'fade',
    // effect: 'cube',
    // cubeEffect: {
    //     shadow: false,
    //     slideShadows: false,
    // },
    allowTouchMove:false,
    grabCursor: true,
    preventClicks: false,
    preventClicksPropagation: false,

}
Vue.use(VueAwesomeSwiper, globalOptions);

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior (to, from, savedPosition) {

        return { x: 0, y: 0 }
    }
});


Vue.config.productionTip = false;
new Vue({
  router,
  render: h => h(App),
}).$mount('#app');


