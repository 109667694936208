import HomePage from './../components/home/Home';
import PortfolioPage from './../components/portfolio/Portfolio';
// import SinglePost from './../components/portfolio/SinglePost';
import AboutPage from './../components/About';

export const routes = [
    {
        path: '/', name: 'home', components: {
            default: HomePage,
        }
    },
    {
        path: '/portfolio', name: 'portfolio', components: {
            default: PortfolioPage,
        },
        children: [
            // {path: '', component: UserStart},
            // {
            //     path: ':id', component: UserDetail, beforeEnter: (to, from, next) => {
            //         console.log('inside route setup');
            //         next();
            //     }
            // },
            {path: ':slug', component: PortfolioPage, name: 'portfolioInside', beforeEnter: (to, from, next) =>{
                    // console.log('inside route setup');
                            next();
                }}
        ]
    },
    {
        path: '/about', name: 'about', components: {
            default: AboutPage,
        }
    },
    // {
    //     path: '/portfolio/:slug', name: 'post', components: {
    //         default: SinglePost,
    //     }
    // },

    // {path: '/redirect-me', redirect: {name: 'home'}},
    {path: '*', redirect: '/'}
];