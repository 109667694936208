<template>
    <div>
        <router-link to="/" class="logo">
            <svg>
                <use v-bind="{'xlink:href': getSvgUrl('#logo')}" :href="getSvgUrl('#logo')"></use>
            </svg>
        </router-link>
        <transition name="slide-fade" >
            <router-view :appData="appData" :posts="posts" :homePosts="homePosts" ></router-view>
        </transition>
    </div>
</template>

<script>
    import HomePage from './components/home/Home';
    import PortfolioPage from './components/portfolio/Portfolio';
    import AboutPage from './components/About';
    import axios from 'axios';


    export default {
        name: 'app',
        data(){
            return{
                appData: {
                    svgSpriteUrl:'',
                    url:'',
                    themeUrl:'',
                    tvsData: '',

                },
                stories:[],
                posts:[],
                homePosts:[],

            }
        },
        components: {
            HomePage,
            PortfolioPage,
            AboutPage

        },
        methods: {
            getSvgUrl(id){
                return this.appData.svgSpriteUrl + id;
            },

        },
        created(){
            this.appData.url = appData.url;
            this.appData.themeUrl = appData.themeUrl;
            this.appData.svgSpriteUrl = appData.svgSprite;

        },
        mounted() {
            axios.get(this.appData.url + '/wp-json/coco/v1/portfolio', {
                params: {

                }
            }).then( (response) =>{
                this.stories = response.data.story;
                this.posts = this.stories.filter(item => item.showHome === false);
                this.homePosts = this.stories.filter(item => item.showHome);
            }).catch((error) => {
                console.log(error);
            });

        }
    }
</script>
<style>
    .about, .portfolio{
        background-color: #fff;
    }
    .about {
        transition: opacity .5s ease;
        overflow: hidden;
    }
    .portfolio .VideoBg {
        background-color: #fff;
        height: 100%!important;
    }

    .slide-fade-enter-active {
        transition: opacity .5s ease;
    }
    .slide-fade-leave-active {
        transition: all .5s ease;
    }
    .slide-fade-leave-to,
    .slide-fade-enter {
        opacity: 0;
    }


    @media only screen and (orientation: portrait),
    only screen and (max-width: 750px) {
        .home .VideoBg {
            width: 100%!important;
        }
        .portfolio .VideoBg {
            width: 100%!important;
        }
        .portfolio .swiper-slide {
            overflow: hidden;
        }
    }


</style>