<template>
    <section class="about">
        <router-link to="/" class="close-btn">
            <svg>
                <use  v-bind="{'xlink:href': getSvgUrl('#close')}" :href="getSvgUrl('#close')"></use>
            </svg>
        </router-link>
        <div class="grid-layout">
            <div class="grid-layout--1">
                <div class="about-content">
                    <div class="wrapper wrapper--1">
                        <h1 class="heading--big heading">{{tvsData.aboutHeading}}</h1>
                    </div>
                    <div class="wrapper wrapper--2">
                        <a class="about__address" target="_blank" :href="tvsData.aboutAddressLink" v-html="tvsData.aboutAddress"></a>
                    </div>
                    <div class="wrapper wrapper--3">
                        <div class="text-block">
                            <p>{{tvsData.aboutRightText}}</p>
                        </div>
                        <h2 class="heading heading--small">{{tvsData.aboutRightHeading}}</h2>
                        <div class="text-block text-block--2" v-html="tvsData.aboutRightList"></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: ['appData'],
        data(){
          return {
              tvsData:{},
              title: 'loaoing'
          }
        },

        methods: {
            getSvgUrl(id){
                return this.appData.svgSpriteUrl + id;
            },

        },
        created(){
            this.tvsData = appData;
        },
        // metaInfo: {
        //     title: () =>{ return 'labas'}, // set a title
        //
        // }
    }
</script>